@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
  background-color: #e2e8f0;
  font-family: roboto;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #22c55e;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 4px;
  border: 4px solid #86efac;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #86efac transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

lds-ring-red {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}

.lds-ring-red div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 4px;
  border: 4px solid #f87171;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #f87171 transparent transparent transparent;
}

.lds-ring-red div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring-red div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring-red div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring-red {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #e2e8f0;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1e293b;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1e293b;
}

:root {
  --map-tiles-filter: brightness(0.6) invert(1) contrast(2) hue-rotate(260deg) saturate(0.3) brightness(0.5);
}


.map-tiles {
  filter: var(--map-tiles-filter, none);

}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

input[type="time"].dark::-webkit-calendar-picker-indicator{
  filter: invert(100%);
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
  background: #1e293b !important;  
  border: 1px solid white !important;
}

